// Derivative from ITCSS -> https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
//
//Settings – used with preprocessors and contain font, colors definitions, etc.
//Tools – globally used mixins and functions. It’s important not to output any CSS in the first 2 layers.
//Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
//Elements – styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
//Objects – class-based selectors which define undecorated design patterns, for example the media object known from OOCSS
//Components – specific UI components. This is where most of our work takes place. We often compose UI components of Objects and Components
//Utilities – utilities and helper classes with ability to override anything which goes before in the triangle, e.g. hide helper class

@import 'settings/_settings.tools.scss';
@import 'settings/_settings.colors.scss';
@import 'settings/_settings.typo.scss';
@import 'settings/_settings.bootstrap.scss';
@import 'settings/_settings.spacing.scss';

@import 'vendor/bootstrap.vendor';
@import 'vendor/owl-carousel.vendor';

@import 'generic/lists.generic';

@import 'elements/layout.elements';
@import 'elements/footer.elements';
@import 'elements/headings.elements';
@import 'elements/buttons.elements';

@import 'components/home-header.components';
@import 'components/pill.components';
@import 'components/form.components';
@import 'components/storypreview.components';
@import 'components/story-list-item.components';

@import 'utilites/utilities.images';
@import 'utilites/utilities.colors';
@import 'utilites/utilities.fonts';
@import 'utilites/borders.utilities';
